import { render, staticRenderFns } from "./DocumentPreview.vue?vue&type=template&id=754f479c&scoped=true&"
import script from "./DocumentPreview.vue?vue&type=script&lang=js&"
export * from "./DocumentPreview.vue?vue&type=script&lang=js&"
import style0 from "./DocumentPreview.vue?vue&type=style&index=0&id=754f479c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754f479c",
  null
  
)

export default component.exports