<template>
  <fragment>
    <div class="grid">
      <div class="col-8">
        <h2>Documents</h2>
      </div>
      <div class="col-4 flex-right">
        <a class="button secondary" @click="addDocButton">Add Document</a>
      </div>
    </div>
    <documents-table :tableid="'clientDocumentsTable'" 
                      :document-templates="templatesWithLocations" 
                      :location-list="clientLocations" :client="true" 
                      @delete="deleted" 
                      @locationModified="getClientDocumets"
                      :loading= loader
    />
    <add-document-modal :selected-templates="templatesWithLocations" @documentModified="getClientDocumets"/>
  </fragment>
</template>

<script>
import micromodal from "micromodal";
import AddDocumentModal from "@/modules/shared/documents/AddDocumentModal";
import { Fragment } from "vue-fragment";
import DocumentsTable from "@/modules/shared/documents/DocumentsTable";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  name: "ClientDocuments",
  title() {
    if (this.$route.hash === "#documents") {
      return `Client Documents`;
    }
  },
  components: {
    DocumentsTable,
    AddDocumentModal,
    Fragment,
  },
  data: () => {
    return {
      clientName: process.env.VUE_APP_CLIENT_NAME,
      clientLocations: [],
      locationsCount: [],
      templates: [],
      overrides: [],
      clientID:process.env.VUE_APP_CLIENT_ID,
      loading: false
    };
  },
  computed: {
    loader() {
      return this.loading
    },
    templatesWithLocations() {
      return this.templates
      // return this.templates
      //   .map((item) => {
      //     item.locations = this.clientLocations.filter(
      //       (location) =>
      //         !this.overrides.some((override) => {
      //           return override.locationID === location.id && override.type === "REMOVE" && override.templateID === item.id;
      //         })
      //     );
      //     return item;
      //   })
      //   .filter((template) => !this.overrides.some((item) => item.templateID === template.id && item.locationID == null))
      //   .map((template) => {
      //     if (template.isDDI) {
      //       template.inherited = true;
      //     }
      //     return template;
      //   });
    },
  },
  mounted() {
    this.getClientDocumets();
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },
  methods: {
    getClientDocumets() {
      this.loading =true;
      restApi.post('/document/getClientDocs',encodeWithParam({clientID: process.env.VUE_APP_CLIENT_ID})).then((data)=>{
         this.loading =false;
        this.templates = data.data.documents;
        this.clientLocations = data.data.locations;
      })
    },
    assignLocations(locations) {
      return this.locationsCount.filter((item) => item.templateID === locations.templateID);
    },
    deleted() {
      this.$apollo.queries.documentTemplateUse.refetch();
    },
    addDocButton() {
      micromodal.show("modal-add-document", {});
    },
    hash() {
      return this.$route.hash === "documents";
    },
  },
};
</script>
